import React from 'react';
import Octicon, { Mail } from '@primer/octicons-react';

function Footer() {
  return (
    <footer>
      <div className="pt-5"></div>
      <div className="w-75 mx-auto border-top brand-line"></div>

      <div className="pb-5 pt-5">
        <p className="text-center">
          <a href="mailto:hello@etssoftworks.com" className="link-unstyled">
            <Octicon icon={Mail} size="medium" ariaLabel="Email" />
          </a>
        </p>
        <p className="text-center">
          Copyright © {new Date().getFullYear()} ETS Softworks LLC
        </p>
      </div>
    </footer>
  );
}

export default Footer;
