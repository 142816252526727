import React from 'react';

function Header() {
  return (
    <header className="pt-3">
      <div className="header-logo">
        <img className="visually-hidden" alt="ETS Softworks logo" />
      </div>
    </header>
  );
}

export default Header;
