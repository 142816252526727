import React from 'react';

function About() {
  return (
    <div>
      <div className="pt-3 pb-3">
        <h1 className="display-4 text-center">
          <span className="heavy-font">E</span>xcellence{' '}
          <span className="heavy-font">T</span>hrough{' '}
          <span className="heavy-font">S</span>implicity
        </h1>
      </div>

      <div className="pt-3 pb-3">
        <h2 className="text-center">Who we are</h2>
        <div className=" text-center">
          We're a small team with a passion for software development and for
          solving real problems. For us, it's more than just developing
          applications. We love working with our customers.
        </div>
      </div>

      <div className="pt-3 pb-3">
        <h2 className="text-center">What we do</h2>
        <div className=" text-center">
          We provide software development services to a variety of businesses,
          helping to solve a variety of problems. The solutions we develop
          deliver value.
        </div>
      </div>
    </div>
  );
}

export default About;
